.h-100 {
  min-height: 100%;
}

.h-80 {
  min-height: 100%;
}

dt,
dd {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.theme--light.h8toolbar {
  //background: #f1f1f1 !important;
  box-shadow: inset 0 0 10px #736f6e !important;
}
.btncrm {
  /*padding: 2px 10px!important;*/
  opacity: 1 !important;
  font: 12px/1.4 'myriad-pro-1', 'myriad-pro-2', 'Lucida Grande', Sans-Serif !important;
  /*background-color: #395f98!important;*/
  background: rgb(233, 241, 252);
  background: linear-gradient(180deg, rgba(233, 241, 252, 1) 0%, rgba(87, 123, 175, 1) 29%, rgba(15, 55, 117, 1) 100%);
  /*background-image: url( '../img/btn_back.jpg' );*/
  background-image: -webkit-gradient(linear, left top, left bottom, from(#b6d3f4), to(#153e7e)) !important;
  background-image: -webkit-linear-gradient(top, #b6d3f4, #153e7e) !important;
  background-image: -moz-linear-gradient(top, #b6d3f4, #153e7e) !important;
  background-image: -ms-linear-gradient(top, #b6d3f4, #153e7e) !important;
  background-image: -o-linear-gradient(top, #b6d3f4, #153e7e) !important;
  color: white !important;
  box-shadow: inset 0 1px 1px white, 0 2px 2px rgba(0, 0, 0, 0.6) !important;
  border: 1px solid #9ac9ff !important;
  font-weight: bold !important;
  text-transform: none !important;
}
.btncrm:hover {
  box-shadow: inset 0 2px 15px white, 0 2px 2px rgba(0, 0, 0, 0.6) !important;
  -moz-box-shadow: inset 0 2px 15px white, 0 2px 2px rgba(0, 0, 0, 0.6) !important;
  -webkit-box-shadow: inset 0 2px 15px white, 0 2px 2px rgba(0, 0, 0, 0.6) !important;
  cursor: pointer !important;
}

.btncrmgrp > * {
  margin-left: 0.5rem;
}
.btncrmgrp > *:first-child {
  margin-left: 0rem;
}
.btncrmgrp > *:last-child {
  margin-right: 0.5rem;
}

.btncrm:focus {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#153e7e), to(#b6d3f4)) !important;
  background-image: -webkit-linear-gradient(top, #153e7e, #b6d3f4) !important;
  background-image: -moz-linear-gradient(top, #153e7e, #b6d3f4) !important;
  background-image: -ms-linear-gradient(top, #153e7e, #b6d3f4) !important;
  background-image: -o-linear-gradient(top, #153e7e, #b6d3f4) !important;
}
.glass {
  background: linear-gradient(147.7deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.25) 100.77%) !important;
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
  //border: 1px solid rgba(255, 255, 255)!important;
  box-sizing: border-box;
  box-shadow: 16px 16px 32px rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(32px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 18px;
}
.glass-light {
  background: linear-gradient(147.7deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.25) 100.77%) !important;
  background-color: transparent !important;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  box-shadow: 16px 16px 32px rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(32px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 18px;
}

.glass-dark {
  background: linear-gradient(147.7deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100.77%) !important;
  background-color: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  box-shadow: 16px 16px 32px rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(32px);
  border-radius: 18px;
}

:root {
  --primary-light-hsl: 200, 100%, 91%;
  --primary-hsl: 200, 100%, 50%;
  --primary-dark-hsl: 200, 100%, 6%;
  --success-hsl: 100, 60%, 50%;
  --error-hsl: 0, 60%, 50%;
}

.loading-text {
  color: #00000061;
  font-size: 14px;
}

.custom-loader-jumper {
  animation: jumpInfinite 1.5s infinite;
  display: flex;
}

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}

.custom-loader-rotate {
  animation: loader-rotate 1s infinite;
  display: flex;
}
@-moz-keyframes loader-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.theme--dark {
  .glass {
    background: linear-gradient(147.7deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100.77%) !important;
    border: 1px solid rgba(0, 0, 0, 0.3) !important;
  }
  .transparent-bg-light {
    //--color: hsl(var(--primary-dark-hsl), .7);
    //background-color: transparent;
    //box-shadow: 0 0 15px 0 var(--color);

    //background: linear-gradient(147.7deg,hsla(0,0%,100%,.5),hsla(0,0%,100%,.25) 100.77%);
    //border: 1px solid hsla(0,0%,100%,.3);
    //backdrop-filter: blur(32px);

    background: linear-gradient(147.7deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.25) 100.77%) !important;
    border: 1px solid hsla(0, 0%, 100%, 0.3);
    box-sizing: border-box;
    box-shadow: 16px 16px 32px rgba(255, 255, 255, 0.08);
    backdrop-filter: blur(32px);
    border-radius: 16px;
    .v-data-table {
      background-color: hsl(var(--primary-dark-hsl), 0.3);
      .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
        background: #3e3e42;
      }
    }
    .loading-text {
      color: #ffffff80;
      font-size: 14px;
    }
  }

  .transparent-bg-dark {
    --color: hsl(var(--primary-dark-hsl), 0.3);
    background-color: hsl(var(--primary-dark-hsl), 0.8);
    box-shadow: 0 0 15px 0 var(--color);
  }
}
