<template>
  <v-card class="glass" :class="topBorder ? 'top-border' : ''" rounded style="max-width: 100%">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6">
          {{ title }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="subTitle">{{ subTitle }}</v-list-item-subtitle>
      </v-list-item-content>
      <slot name="actionButtons"></slot>
    </v-list-item>
    <v-divider></v-divider>
    <v-container fluid>
      <div v-if="loading">
        <v-row align-content="center" justify="center" no-gutters>
          <v-col cols="12" class="pb-2">
            <v-progress-linear indeterminate rounded></v-progress-linear>
          </v-col>
          <v-col class="text-center loading-text" cols="12"> {{ loadingText }} </v-col>
        </v-row>
        <slot name="loading"></slot>
      </div>
      <slot v-else></slot>
    </v-container>
  </v-card>
</template>
<style scoped>
.top-border {
  border-top: 3px solid var(--v-primary-base) !important;
}
</style>
<script lang="ts" src="./h8-card.component.ts"></script>
