<template>
  <v-main>
    <div style="width: 100vw; height: 20px; background-color: #224474; clear: both">
    </div>
    <div style="width: 100vw; height: 10vh; background-color: #ffffff; clear: both; background-image: linear-gradient(to top, #B4CFF3 0%, #FCFEFF 100%);">
    </div>
    <v-row id="wheet_img" no-gutters justify="center" align-content="center">
      <router-view></router-view>
    </v-row>
    <div style="width: 100vw; height: 10vh; background-color: #ffffff; clear: both; background-image: linear-gradient(to top, #B4CFF3 0%, #FCFEFF 100%);">
    </div>
    <div style="width: 100vw; height: 20px; background-color: #224474; clear: both">
    </div>
  </v-main>
</template>
<script lang="ts" ></script>
<style scoped>
#wheet_img{
  background-image: url('../content/images/wheet_img.jpg');
  background-size:cover;
  background-position:center center;
  background-attachment: fixed;
  height: calc(80vh - 40px);
}
</style>
