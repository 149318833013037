<template>
  <div style="height: 100%">
    <!--    <v-navigation-drawer app small dark :permanent="$vuetify.breakpoint.mdAndUp" v-model="drawer" style="background-image: linear-gradient(to bottom, #668ec3, #091d37) !important">-->
    <v-navigation-drawer v-model="drawer" app class="d-block d-lg-none" temporary :dark="!$vuetify.theme.dark" color="appNavColor">
      <v-list dense v-if="$store.getters.authenticated">
        <div v-for="item of userMenu">
          <v-list-group v-if="item.children" :prepend-icon="item.icon" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(children, i) in item.children" :key="i" link :to="children.route" :value="i">
              <v-list-item-icon>
                <v-icon>{{ children.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ children.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :to="item.route">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      :dense="!$vuetify.breakpoint.mobile"
      elevation="4"
      fixed
      style="padding-left: 0px"
      :dark="!$vuetify.theme.dark"
      color="appNavColor"
      v-if="$store.getters.authenticated && isMenuVisible"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" app class="d-block d-lg-none"></v-app-bar-nav-icon>
      <v-img
        v-if="organization.logo"
        v-bind:src="'data:' + organization.logoContentType + ';base64,' + organization.logo"
        contain
        max-height="100%"
        max-width="120px"
        class="mx-1"
      ></v-img>
      <v-img v-else :src="require('../content/images/logo_white.png')" contain max-height="100%" max-width="100px" class="mx-1"></v-img>
      <v-slide-group
        multiple
        show-arrows
        class="d-none d-lg-block"
        v-if="$store.getters.authenticated && isMenuVisible"
        :dark="!$vuetify.theme.dark"
      >
        <v-slide-item v-for="item in userMenu" :key="item.title" v-slot="{ active, toggle }" v-if="item.title">
          <v-menu open-on-hover offset-y v-if="item.children" :dark="!$vuetify.theme.dark">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" tile plain>
                {{ item.title }}
                <v-icon right>
                  {{ item.icon }}
                </v-icon>
              </v-btn>
            </template>
            <v-list color="appNavColor">
              <v-list-item v-for="(subMenu, index) in item.children" :key="index" link :to="subMenu.route" dense>
                <v-list-item-icon v-if="subMenu.icon">
                  <v-icon>{{ subMenu.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ subMenu.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn tile plain v-else :to="item.route">
            {{ item.title }}
            <v-icon right>
              {{ item.icon }}
            </v-icon>
          </v-btn>
        </v-slide-item>
      </v-slide-group>
      <v-spacer></v-spacer>
      <template>
        <div>
          <div class="d-inline pr-3">
            <a v-if="!inProduction" href="/h2-console/" style="text-decoration: none" target="_tab">
              <v-icon>mdi-database-check</v-icon>
            </a>
          </div>
          <div v-if="$can('view', 'Application Metrics')" class="d-inline pr-3">
            <v-icon @click="$router.push({ name: 'JhiMetricsComponent' })">mdi-gauge</v-icon>
          </div>
          <!-- <div class="d-inline pr-3">
            <v-icon @click="toggleDarkMode()">{{ themeIcon }}</v-icon>
          </div> -->
        </div>
      </template>

      <v-menu bottom min-width="200px" rounded offset-y>
        <template v-slot:activator="{ on }">
          <v-btn fab small v-on="on" color="primary" class="btncrm">
            <v-avatar size="36">
              <span class="text-h6">{{ userShortName }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card class="pa-2">
          <v-list>
            <v-list-item>
              <div class="mx-auto text-center">
                <h3>{{ userFullName }}</h3>
                <p class="text-caption mt-1">
                  {{ userEmail }}
                </p>
              </div>
            </v-list-item>
            <v-divider class="py-1"></v-divider>
            <div v-if="multiOrganizationView">
              <v-list>
                <v-list-item
                  v-for="org in orgList"
                  :key="org.id"
                  link
                  @click="onOrganizationChange(org)"
                  dense
                  :class="{ 'selected-org': isSelected(org) }"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-domain</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title :class="{ 'selected-text': isSelected(org), 'primary--text': isSelected(org) }">
                    {{ org.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>

              <v-divider class="py-1"></v-divider>

              <v-list-item link dense v-if="$can('view', 'Organization')" @click="loadOrganizations">
                <v-list-item-icon>
                  <v-icon>mdi-domain</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Show All Organizations</v-list-item-title>
              </v-list-item>
              <v-divider class="py-1" v-if="$can('view', 'Organization')"></v-divider>
            </div>
            <v-list-item link dense v-if="authenticated" to="/account/settings/general-settings">
              <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Settings </v-list-item-title>
            </v-list-item>
            <v-list-item link dense @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <h8-overlay :isVisible="isShow"></h8-overlay>
    <v-main :class="$vuetify.theme.dark ? 'dark-background' : 'light-background'" style="height: 100%">
      <v-container fluid style="height: 100%">
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer app fixed padless height="24px" style="box-shadow: inset 0 1px 10px #000000">
      <span style="margin-left: 10px; font-size: small">Version: {{ version }} </span>
      <v-spacer></v-spacer>

      <span style="margin-right: 10px; font-size: small"
        >©Powered by:
        <a href="https://www.height8tech.com" rel="noopener" target="_blank" class="ml-25">Height8 Technologies</a>
      </span>
    </v-footer>
  </div>
</template>
<script lang="ts" src="./layout-vertical.component.ts"></script>
<style scoped>
.light-background {
  background-image: linear-gradient(to bottom, #668ec3, #091d37);
}
.dark-background {
  //background: radial-gradient(circle at 10% 20%, rgb(90, 92, 106) 0%, rgb(32, 45, 58) 81.3%);
  //background: radial-gradient(circle at 10% 20%, rgb(69, 86, 102) 0%, rgb(34, 34, 34) 90%);
  background-image: radial-gradient(circle at 10% 20%, rgb(64, 60, 60) 0%, rgb(69, 86, 102) 90%);
}
</style>
