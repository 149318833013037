<template>
  <div class="d-flex flex-row h-100" style="max-width: 100%">
    <div class="flex-grow-0 d-lg-block d-none mr-4" v-if="sidebarTitle" :style="sidebarStyle">
      <v-card dark color="transparent" flat>
        <v-row justify="center">
          <v-card-title class="subtitle-2"> {{ sidebarTitle }} </v-card-title>
        </v-row>
        <slot name="sidebar"> </slot>
      </v-card>
    </div>
    <div class="flex-grow-1 content">
      <v-card class="h-100 transparent-bg-light">
        <v-toolbar class="h8toolbar" dense>
          <v-row no-gutters style="width: 100%">
            <v-toolbar-title class="toolbar-title">{{ title }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="d-md-block d-none">
              <v-btn-toggle group rounded dense>
                <v-btn class="" v-if="backEnable" v-on:click="previousState">
                  <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <slot name="action-btn"></slot>
                <v-btn class="" v-if="editEnable" v-on:click="onEditEntities">
                  <v-icon>mdi-clipboard-edit</v-icon>
                </v-btn>

                <v-menu bottom offset-y v-if="deleteEnable">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="" v-if="deleteEnable" v-bind="attrs" v-on="on">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <v-card class="pa-2" flat min-width="12rem">
                    <p class="caption">Confirm deletion</p>
                    <v-row no-gutters justify="space-around">
                      <v-btn small color="primary">Cancel</v-btn>
                      <v-btn v-on:click="onDeleteEntities" small color="error">Delete</v-btn>
                    </v-row>
                  </v-card>
                </v-menu>
              </v-btn-toggle>
            </div>
            <div class="d-md-none d-block" v-if="menuVisible">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item v-if="backEnable" v-on:click="previousState">
                    <v-list-item-icon class="mr-2">
                      <v-icon small>mdi-arrow-left</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Back</v-list-item-title>
                  </v-list-item>
                  <slot name="action-btn-list"></slot>
                  <v-list-item v-if="editEnable" v-on:click="onEditEntities">
                    <v-list-item-icon class="mr-2">
                      <v-icon small>mdi-clipboard-edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <template>
                    <v-menu bottom offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item dense v-on="on" v-bind="attrs" v-if="deleteEnable">
                          <v-list-item-icon class="mr-2"><v-icon small>mdi-delete</v-icon></v-list-item-icon>
                          <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>
                      </template>
                      <v-card class="pa-2" flat min-width="12rem">
                        <p class="caption">Confirm deletion</p>
                        <v-row no-gutters justify="space-around">
                          <v-btn small color="primary">Cancel</v-btn>
                          <v-btn v-on:click="onDeleteEntities" small color="error">Delete</v-btn>
                        </v-row>
                      </v-card>
                    </v-menu>
                  </template>
                </v-list>
              </v-menu>
            </div>
          </v-row>
        </v-toolbar>
        <div class="h-100 ma-3">
          <v-navigation-drawer v-model="sidebarVisibility" class="d-block d-lg-none" temporary color="#1b2b3c" app dark>
            <v-container>
              <v-card dark color="transparent" flat>
                <v-row justify="center">
                  <v-card-title class="subtitle-2"> {{ sidebarTitle }} </v-card-title>
                </v-row>
              </v-card>
              <slot name="sidebar"></slot>
            </v-container>
          </v-navigation-drawer>
          <slot></slot>
        </div>
      </v-card>
      <v-btn
        fab
        color="primary"
        fixed
        right
        bottom
        elevation="10"
        class="d-block d-lg-none mb-2"
        @click="sidebarVisibility = !sidebarVisibility"
        ><v-icon>mdi-filter-menu</v-icon></v-btn
      >
    </div>
  </div>
</template>
<script lang="ts" src="./h8-window.component.ts"></script>
<style scoped lang="css">
.content {
  max-width: 100%;
  overflow-x: auto;
}
.toolbar-title {
  align-self: center;
  max-width: calc(100% - 250px);
}
@media only screen and (max-width: 960px) {
  .toolbar-title {
    max-width: calc(100% - 50px);
  }
}
.theme--light.v-btn--active:before,
.theme--light.v-btn--active:hover:before {
  opacity: 0;
}
</style>
