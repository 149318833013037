<template>
    <v-dialog @input="(event) => $emit('closeDialog',event)" v-model="dialogState" :max-width="maxWidth" :fullscreen="fullscreen" :transition="transition" :content-class="contentClass">
      <v-toolbar dark height="35" dense color="#1b2b3c">
          <v-toolbar-title class="dialog_title">{{titleDialog}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small icon @click="$emit('closeDialog')"><v-icon small>mdi-close</v-icon></v-btn>
        </v-toolbar>
      <v-card tile class="pa-2">
        <slot></slot>
      </v-card>
    </v-dialog>
</template>
<script lang="ts" src="./h8-dialog.component.ts"></script>
<style>
.dialog_title{
  font-size: 1.0rem !important;
}
</style>
